<template>
    <div class="rectify-list">
<!--      <h3 class="model-title">整改记录</h3>-->
      <van-tabs type="card"  v-model="params.type"  @change="changeType">
<!--        <van-tab title="疫情防控" name="yqfk,yqfk2"></van-tab>-->
        <van-tab title="行业检查" :name="aqscTables"></van-tab>
        <van-tab title="专项检查" :name="zxjcTables"></van-tab>
      </van-tabs>
      <van-tabbar v-model="params.status" @change="changeStatus">
        <van-tabbar-item name="1,2,3" icon="home-o">全部</van-tabbar-item>
        <van-tabbar-item name="1" icon="setting-o">待整改</van-tabbar-item>
        <van-tabbar-item name="2" icon="friends-o">待确认</van-tabbar-item>
        <van-tabbar-item name="3" icon="passed">已整改</van-tabbar-item>
      </van-tabbar>
      <van-tabs @click="timeChange">
        <van-tab title="当日"></van-tab>
        <van-tab title="本周"></van-tab>
        <van-tab title="本月"></van-tab>
        <van-tab title="自定义"></van-tab>
      </van-tabs>
      <div class="des">共{{total}}条记录</div>
      <van-list
          :immediate-check="false"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          @load="onLoad"
      >
        <div class="check-list" v-for="(item,index) in list" :key="index" >
<!--          <div class="item-choose">-->
<!--            <van-checkbox v-model="checked[item.id]"></van-checkbox>-->
<!--          </div>-->
          <div class="item-index">
            {{index+1}}
          </div>

          <div class="item-info">
            {{item.name?item.name:item.infoId}}
            <span v-if="rectifyType=='zxjc'">({{zxjcJson[item.type].name}})</span>
          </div>
          <div class="item-check">
            <div class="item-user"><van-icon name="user-o" size="14" />{{item.remark?item.remark:'--'}}</div>
            <div style="font-size: 0.6rem;">{{item.createTime?item.createTime.substr(0,16):"--"}}</div>
          </div>
          <div class="item-btn" @click="viewDetail(item)">
            {{parseResult(item)}}
            <span class="item-click"><van-icon name="arrow" /></span>
          </div>
<!--          <div class="item-check-res" @click="viewDetail(item)">
            <span class="item-click"><van-icon name="arrow" /></span>
            {{parseRectify(item.rectify).split("\n")[0]}}
            <br>
            {{parseRectify(item.rectify).split("\n").length>1?parseRectify(item.rectify).split("\n")[1]:""}}
          </div>-->
        </div>

        <!--      <van-cell v-for="item in list" :key="item" :title="item" />v-for="item in list" :key="item" :title="item"-->
      </van-list>
      <van-calendar v-model="calendarShow" type="range" @confirm="onConfirm" :min-date="minDate" :max-range="365" :style="{ height: '500px' }"  />

      <!--    整改纪录闭环弹框-->
      <van-popup v-model="recordPopup" position="right" :style="{ height: '100%',width:'100%',background:'#f4f6fa' }">
        <div class="model-title align-center"><van-icon name="arrow-left" size="28" @click="recordPopup=false" />整改详情</div>
        <detail v-if="rectifyType=='yqfk'"  :recordData="recordData" :dict="dict" ref="record"></detail>
        <rectify v-else :recordData="recordData" :dict="checkItem" ref="record1"></rectify>
      </van-popup>

    </div>
</template>
<!--状态 -1暂存 0通过  1需要整改  2整改提交  3整改完成 -->
<script>
import {getStartEndByMonth,getWeekEndDate,getRecentMonth,formatDate} from "@/api/time"
import {
  PullRefresh,List,Cell,Col, Row,Icon,Popup,Checkbox,Button,Tab, Tabs,Calendar,Tabbar,TabbarItem
} from 'vant';
import detail from "./detail"
import rectify from "./rectify"
export default {
  components:{detail,rectify,
    [Calendar.name]: Calendar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Button.name]: Button,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Row.name]: Row,
    [Col.name]: Col,
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [Checkbox.name]: Checkbox,
    [Icon .name]: Icon
  },
  props:{

  },
  watch:{

  },
  data(){
    return {
      checked:{

      },
      calendarShow:false,
      error:false,
      loading:false,
      list:[],
      total:0,
      finished:false,
      refreshing:false,
      dict:{},
      recordPopup:false,
      recordData:{},
      minDate:new Date(2021,9,1),
      params:{
        size: 6,
        page: 0,
        person:"",
        startTime:"",
        endTime:"",
        status:"1,2,3",
        type:""
      },
      aqscTables:"",
      aqsc:{

      },
      wmcs:{

      },
      checkTable:{},
      checkItem:{},
      rectifyType:"yqfk",
      zxjcTables:"",
      zxjcJson:{}
    }
  },
  methods: {
    // 获取所有检查表
    getCheckTables(){
      return new Promise(resolve => {
        this.$http({
          url: "/zuul/cultural/check/table/raw",
          method: 'get'
        }).then(result => {
          if(result.data.success){
            console.log(result.data.data);
            this.aqsc = {};
            result.data.data.map(item=>{
              if(!this.checkTable[item.id]){
                this.checkTable[item.id] = item.typeList;
              }
              if(item.type=='aqsc'){
                if(this.aqscTables){
                  this.aqscTables += ","+item.id;
                }else{
                  this.aqscTables = item.id;
                }
                if(!this.aqsc[item.infoType]){
                  this.aqsc[item.infoType] = item.typeList;
                }
              }else if(item.type=='wmcs'){
                if(!this.wmcs[item.infoType]){
                  this.wmcs[item.infoType] = item.typeList;
                }
              }if(item.type=='zxjc'){
                if(this.zxjcTables){
                  this.zxjcTables += ","+item.id;
                }else{
                  this.zxjcTables = item.id;
                }
                if(!this.zxjcJson[item.id]){
                  this.zxjcJson[item.id] = item;
                }
              }
            })

            resolve(true);
          }

        })
      })
    },
    timeChange(name, title){
      this.params.page = 0;
      if(name==1){
        this.params.startTime = getWeekEndDate()[0];
        this.params.endTime = getWeekEndDate()[1];
        this.getList();
      }else if(name==2){
        this.params.startTime = getStartEndByMonth()[0];
        this.params.endTime = getStartEndByMonth()[1];
        this.getList();
      }else if(name==0){
        this.params.startTime = formatDate(new Date()) + " 00:00:00";
        this.params.endTime = formatDate(new Date())+ "  23:59:59";
        this.getList();
      }else if(name==3){
        this.calendarShow = true;
      }
    },
    changeType(index,title){
      console.log(index,"index",title)
      if(title=='疫情防控'){
        this.rectifyType = 'yqfk,yqfk2';
      }else if(title=='行业检查'){
        this.rectifyType = "aqsc";
      }else if(title=='专项检查'){
        this.rectifyType = "zxjc";
      }else{
        this.rectifyType = "wmcs";
      }
      this.params.page = 0;
      this.getList();
    },
    changeStatus(index){
      this.params.page = 0;
      this.getList();
    },
    onConfirm(date) {
      let [start, end] = date;
      this.params.page = 0;
      this.calendarShow = false;
      this.params.startTime =this.formatDate(start);
      this.params.endTime =this.formatDate(end);
      this.getList();
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    // 获取检查项
    getDictList() {
      if(localStorage.getItem("wljptofficeIds") == '32010500'){
        this.params.person = "";
      }else{
        this.params.person = localStorage.getItem("wljptuser");
      }
    },
    // 获取需要整改检查记录
    getList() {
      this.loading = true;
      this.finished =false;
      let _this = this;
      _this.list = [];
      this.$http({
        url: "/zuul/cultural/check",
        method: "get",
        params: this.params
      }).then(res => {
        if (res.data.success && res.data.data) {
          _this.total = res.data.data.total;
          _this.list = res.data.data.results;
          _this.list.map(item=>{
            _this.$set(this.checked,item.id,false);
          })
        }
        console.log(this.list.length,this.total,"total")
        this.isFinished();
        this.loading = false;
      }).finally(response => {

      })
    },
    // 解析整改项
    parseRectify(str) {
      let res = "无整改项";
      if (str) {
        let typeJson = {};
        let arr = str.split(",");
        arr.map(item => {
          if(this.dict[item]){
            if (!typeJson[this.dict[item].type]) {
              typeJson[this.dict[item].type] = {};
            }
            typeJson[this.dict[item].type][item] = this.dict[item].name;
          }

        })
        res = "共" + arr.length + "项需整改 \n";
        for (let k in typeJson) {
          res += k + ":";
          let n = 0;
          for (let j in typeJson[k]) {

            if (n == 0) {
              res += typeJson[k][j]
            } else {
              res += "," + typeJson[k][j]
            }
            n++;
          }
          res += n + "项需整改。";
        }
      }
      return res;
    },
    // 解析检查结果
    parseJson(jsonstr,value){
      let str = "";
      if(jsonstr){
        let content= JSON.parse(jsonstr);
        str = content[value]
      }
      return str;
      // return value
    },
    viewDetail(item){
      this.$http({
        url: "/zuul/cultural/check/" + item.id,
        method: "get"
      }).then(res=>{
        if(res.data.success){
          this.recordPopup = true;
          this.checkItem = this.checkTable[res.data.data.type];
          this.dict = {};
          this.checkTable[res.data.data.type].map((item,index)=>{
            if(item){
              this.dict[item.id] = {
                ...item,
                sort:index + 1,
                type:item.groupName
              }
            }
          })
          this.recordData = res.data.data;
        }
      })
    },
    parseResult(item){
      // <!--状态 -1暂存 0通过  1需要整改  2整改提交  3整改完成 -->
      let str = "检查通过";
      if(item.status==0){
        str = "检查通过";
      }else if(item.status==1){
        str = "需要整改";
      }else if(item.status==3){
        str = "整改完成";
      }else if(item.status==2){
        str = "反馈整改";
      }
      return str;
    },
    isFinished(){
      if(this.list.length>=this.total){
        this.finished = true;
      }
    },
    onRefresh(){
      if(this.list.length<this.total){
        this.loading = true;
        this.params.page++;
        this.onLoad();
      }else{
        this.loading = false;
        this.refreshing = false;
        this.finished = true;
      }

    },
    getOnLoadList(){

      this.loading = true;
      return new Promise(resolve => {
        this.$http({
          url: "/zuul/cultural/check",
          method: "get",
          params: this.params
        }).then(res=>{
          resolve(res);

        }).catch(e=>{
          this.error = true;
        }).finally(e=>{

        })
      })
    },
    onLoad(){
      let _this = this;
      console.log(this.params.page)
      Promise.all([this.getOnLoadList()]).then(res=>{
        console.log(res,"getOnLoadList")
        if(res[0].data.success && res[0].data.data.results){
          if(this.params.page>0){
            _this.list = _this.list.concat(res[0].data.data.results)
          }else{
            _this.list = res[0].data.data.results;
          }
          console.log("concat",_this.list.length,this.total)
          if(_this.list.length>= _this.total){
            _this.finished = true;
          }else{
            _this.params.page++;
          }
        }
        this.loading = false;
      })
    },
  },
  mounted(){


    let promise = new Promise(resolve => {
      this.getDictList()
      resolve()
    })
    promise.then(res =>{
        Promise.all([this.getCheckTables()]).then(res=>{
            this.params.type = this.aqscTables;
            this.timeChange(0)
        })
    })
  }
}
</script>

<style scoped lang="less">
.rectify-list /deep/.van-tabs__nav--card .van-tab{
  color: #349DFF;
  border-right: 1px solid #349DFF;
}
.rectify-list /deep/.van-tabs__nav--card{
  border: 1px solid #349DFF;
  border-right: none;
}
.rectify-list /deep/ .van-tabs--card>.van-tabs__wrap{
  height: 45px;
}

.rectify-list /deep/ .van-tabs__nav--card{
  height: 45px;
}
.rectify-list /deep/.van-tabs__nav--card .van-tab.van-tab--active{
  color:white;
  background: #349DFF;
}
.rectify-list /deep/.van-tabs__nav--card{
  margin: 0px;
}
.rectify-list{
  color: #323233;
  padding-bottom: 60px;
  height: calc(100vh - 60px);
  overflow-y: scroll;
  .model-title{
    letter-spacing: 2px;
    font-weight: 800;
    text-align: left;
    line-height: 40px;
    position: relative;
    padding: 0px 0px 0px 25px;
    margin: 0px;
    background: #f7f8fa;
    //background: #DFE0DF;
    .van-icon{
      position: absolute;
      left: 0px;
      top: 8px;
      font-weight: normal;
      color: #969799;
    }
  }
  .align-center{
    text-align: center;
  }
}
.des{
  padding: 10px 20px;
  color: rgba(69, 90, 100, 0.6);
  background: #f7f8fa;
}
.check-list{
  padding: 10px 20px 2px;
  font-size: 0.8rem;
  border-bottom: 1px solid #ebedf0;;
  >div{
    display: inline-block;
  }
  .item-choose{
    position: relative;
    width: 30px;
    vertical-align: middle;
  }
  .item-index{
    width: 30px;
    position: relative;
    //top: -25px;
    vertical-align: middle;
  }
  .item-check{
    width: 100px;
    line-height: 1.2rem;
    vertical-align: middle;
    .item-user{
      padding: 5px 0px 2px 0px;
      .van-icon{
        position: relative;
        top: 2px;
        margin-right: 2px;
      }
    }
  }
  .item-btn{
    width: 65px;
    text-align:right;
    position: relative;
    .item-click{
      position: absolute;
      top: 2px;
      right: -20px;
      width: 30px;
      font-size: 1rem;
      color: #999;
    }
  }
  .item-check-res,.item-rectify{
    padding: 0 2px;
    vertical-align: middle;
    word-break: break-word;
    width: calc(100vw - 290px);
  }
  .item-check-res{
    position: relative;
    padding-left: 10px;

    //color: green;
    //cursor: pointer;
  }
  .item-info{
    width: calc(100vw - 250px);
    padding-right: 10px;
    vertical-align: middle;
    text-align: left;
  }
}
</style>
