<template>
  <div class="detail">
    <div style="position:fixed;top: 40px;left: 0px;width: calc(100vw);z-index: 2;">
      <van-notice-bar
          color="#1989fa" background="#ecf9ff"
          wrapable
          left-icon="info-o" :scrollable="false"
          :text="'此次检查共'+recordData.problemList.length + '项需要整改，检查时间:'+recordData.createTime + noticeStr"
      />
    </div>
    <div v-if="Object.keys(groupForm).length>0">
      <div v-for="(items,k,i) in groupForm" v-bind:key="'form'+i">
        <div class="form-title" style="border-bottom: 1px solid #eee;">{{k}}</div>
        <div v-for="(obj, key) in items" v-bind:key="key">
          <div class="item-list">
            <div class="item-title">
<!--              <span class="item-index">{{(key+1)}}</span> -->
              {{  obj.name}}
            </div>
            <div style="padding: 10px;">整改问题及要求</div>
            <div class="item-check-des">{{formData[obj.id]['des']}}</div>
            <div class="form-label"><span></span>检查照片</div>
            <div>
              <van-uploader v-model="fileList[obj.id]" :max-count="fileList[obj.id].length" :deletable="false"  />
            </div>
          </div>
          <div class="rectify-content">
            <div class="rectify-title">整改情况</div>
            <div v-if="isRectify">
              <van-form :label-width="labelWidth" :ref="'rectifyForm'+ obj.id"  @submit="onSubmit(obj.id)">
                <van-field name="remark" rows="3"
                           :rules="[{ required:true, message: '',ruleId:obj.id  }]"
                           v-model="rectify[obj.id]['des']"
                           autosize type="textarea"  maxlength="200" show-word-limit label="" placeholder="整改反馈" />
                <div class="form-label"><span></span>上传照片</div>
                <van-field name="uploader" label=""  :rules="[{ required:true,message: '至少上传一张照片',ruleId:obj.id  }]">
                  <span class="label-tips">*</span>
                  <template #input>
                    <van-uploader  capture="camera"   v-model="rectify[obj.id]['pics']"  :before-read="beforeRead"  :max-count="6" />
                  </template>
                </van-field>
                <div class="item-btn">
                  <van-button plain block type="default" :loading="loading"  native-type="submit">提交</van-button>
                </div>
              </van-form>
            </div>
            <div v-else>
              <van-field name="remark" rows="3"
                         :rules="[{ required:true, message: '',ruleId:obj.id  }]"
                         v-model="rectify[obj.id]['des']"
                         autosize type="textarea"  maxlength="200" show-word-limit label="" placeholder="整改反馈" />
              <div class="form-label"><span></span>整改照片</div>
              <div v-if="rectify[obj.id]['pics'].length>0" style="padding: 8px 16px 0px;">
                <van-uploader  v-model="rectify[obj.id]['pics']"  :max-count="rectify[obj.id]['pics'].length" :deletable="false"  />
              </div>
              <div v-else style="padding: 8px 16px 0px;">
                无
              </div>
            </div>


          </div>


        </div>

      </div>
    </div>
<!--        <div class="form-title"  v-if="formShow1">场所防疫</div>
        <div class="type-title" v-for="(obj, key,index) in formDict1" v-bind:key="key">
          <div class="item-list">
           <div class="item-title"> <span class="item-index">{{(index+1)}}</span> {{  obj.name}}</div>
            <div style="padding: 10px;">整改问题及要求</div>
            <div class="item-check-des">{{formData[obj.id]['des']}}</div>
            <div class="form-label"><span></span>检查照片</div>
            <div>
              <van-uploader v-model="fileList[obj.id]" :max-count="fileList[obj.id].length" :deletable="false"  />
            </div>
          </div>
          <div class="rectify-content">
            <div class="rectify-title">整改情况</div>
            <div v-if="isRectify">
              <van-form :label-width="labelWidth" :ref="'rectifyForm'+ obj.id"  @submit="onSubmit(obj.id)">
                <van-field name="remark" rows="3"
                           :rules="[{ required:true, message: '',ruleId:obj.id  }]"
                           v-model="rectify[obj.id]['des']"
                           autosize type="textarea"  maxlength="200" show-word-limit label="" placeholder="整改反馈" />
                <div class="form-label"><span></span>上传照片</div>
                <van-field name="uploader" label=""  :rules="[{ required:true,message: '至少上传一张照片',ruleId:obj.id  }]">
                  <span class="label-tips">*</span>
                  <template #input>
                    <van-uploader  capture="camera"   v-model="rectify[obj.id]['pics']"  :before-read="beforeRead"  :max-count="6" />
                  </template>
                </van-field>
                <div class="item-btn">
                  <van-button plain block type="default" :loading="loading"  native-type="submit">提交</van-button>
                </div>
              </van-form>
            </div>
            <div v-else>
              <van-field name="remark" rows="3"
                         :rules="[{ required:true, message: '',ruleId:obj.id  }]"
                         v-model="rectify[obj.id]['des']"
                         autosize type="textarea"  maxlength="200" show-word-limit label="" placeholder="整改反馈" />
              <div class="form-label"><span></span>整改照片</div>
              <div v-if="rectify[obj.id]['pics'].length>0" style="padding: 8px 16px 0px;">
                <van-uploader  v-model="rectify[obj.id]['pics']"  :max-count="rectify[obj.id]['pics'].length" :deletable="false"  />
              </div>
              <div v-else style="padding: 8px 16px 0px;">
                无
              </div>
            </div>


          </div>


        </div>


      <div class="form-title"  v-if="formShow2">员工防护</div>
    <div class="type-title" v-for="(obj, key,index) in formDict2" v-bind:key="key">
      <div class="item-list">
        <div class="item-title"> <span class="item-index">{{(index+1)}}</span> {{  obj.name}}</div>
        <div style="padding: 10px;">整改问题及要求</div>
        <div class="item-check-des">{{formData[obj.id]['des']}}</div>
        <div class="form-label"><span></span>检查照片</div>
        <div>
          <van-uploader v-model="fileList[obj.id]" :max-count="fileList[obj.id].length" :deletable="false"  />
        </div>
      </div>
      <div class="rectify-content">
        <div class="rectify-title">整改情况</div>
        <div v-if="isRectify">
          <van-form :label-width="labelWidth" :ref="'rectifyForm'+ obj.id"  @submit="onSubmit(obj.id)">
            <van-field name="remark" rows="3"
                       :rules="[{ required:true, message: '',ruleId:obj.id  }]"
                       v-model="rectify[obj.id]['des']"
                       autosize type="textarea"  maxlength="200" show-word-limit label="" placeholder="整改反馈" />
            <div class="form-label"><span></span>上传照片</div>
            <van-field name="uploader" label=""  :rules="[{ required:true,message: '至少上传一张照片',ruleId:obj.id  }]">
              <span class="label-tips">*</span>
              <template #input>
                <van-uploader   capture="camera"  v-model="rectify[obj.id]['pics']"  :before-read="beforeRead"  :max-count="6" />
              </template>
            </van-field>
            <div class="item-btn">
              <van-button plain block type="default" :loading="loading"  native-type="submit">提交</van-button>
            </div>
          </van-form>
        </div>
        <div v-else>
          <van-field name="remark" rows="3"
                     :rules="[{ required:true, message: '',ruleId:obj.id  }]"
                     v-model="rectify[obj.id]['des']"
                     autosize type="textarea"  maxlength="200" show-word-limit label="" placeholder="整改反馈" />
          <div class="form-label"><span></span>整改照片</div>
          <div v-if="rectify[obj.id]['pics'].length>0" style="padding: 8px 16px 0px;">
            <van-uploader  v-model="rectify[obj.id]['pics']"  :max-count="rectify[obj.id]['pics'].length" :deletable="false"  />
          </div>
          <div v-else style="padding: 8px 16px 0px;">
            无
          </div>
        </div>


      </div>


    </div>

      <div class="form-title"  v-if="formShow3">消费者防护</div>
    <div class="type-title" v-for="(obj, key,index) in formDict3" v-bind:key="key">
      <div class="item-list">
        <div class="item-title"> <span class="item-index">{{(index+1)}}</span> {{  obj.name}}</div>
        <div style="padding: 10px;">整改问题及要求</div>
        <div class="item-check-des">{{formData[obj.id]['des']}}</div>
        <div class="form-label"><span></span>检查照片</div>
        <div>
          <van-uploader v-model="fileList[obj.id]" :max-count="fileList[obj.id].length" :deletable="false"  />
        </div>
      </div>
      <div class="rectify-content">
        <div class="rectify-title">整改情况</div>
        <div v-if="isRectify">
          <van-form :label-width="labelWidth" :ref="'rectifyForm'+ obj.id"  @submit="onSubmit(obj.id)">
            <van-field name="remark" rows="3"
                       :rules="[{ required:true, message: '',ruleId:obj.id  }]"
                       v-model="rectify[obj.id]['des']"
                       autosize type="textarea"  maxlength="200" show-word-limit label="" placeholder="整改反馈" />
            <div class="form-label"><span></span>上传照片</div>
            <van-field name="uploader" label=""  :rules="[{ required:true,message: '至少上传一张照片',ruleId:obj.id  }]">
              <span class="label-tips">*</span>
              <template #input>
                <van-uploader  capture="camera"  v-model="rectify[obj.id]['pics']"  :before-read="beforeRead"  :max-count="6" />
              </template>
            </van-field>
            <div class="item-btn">
              <van-button plain block type="default" :loading="loading"  native-type="submit">提交</van-button>
            </div>
          </van-form>
        </div>
        <div v-else>
          <van-field name="remark" rows="3"
                     :rules="[{ required:true, message: '',ruleId:obj.id  }]"
                     v-model="rectify[obj.id]['des']"
                     autosize type="textarea"  maxlength="200" show-word-limit label="" placeholder="整改反馈" />
          <div class="form-label"><span></span>整改照片</div>
          <div v-if="rectify[obj.id]['pics'].length>0" style="padding: 8px 16px 0px;">
            <van-uploader  v-model="rectify[obj.id]['pics']"  :max-count="rectify[obj.id]['pics'].length" :deletable="false"  />
          </div>
          <div v-else style="padding: 8px 16px 0px;">
            无
          </div>
        </div>


      </div>


    </div>-->
    <div>
      <div class="form-label" style="padding-bottom: 5px;">
        <span v-if="isRectify">整改人签名</span>
        <span v-if="buttonShow">确认人签名</span>
      </div>
      <div style="border: 1px solid #ebedf0;height: 80px;margin: 8px 16px;border-radius: 4px;" @click="openSign('rectifySign')">
        <div v-if="rectifySigned">
          <img :src="sign" height="80px">
<!--          <img v-if="buttonShow" :src="recordData.confirmSign" height="80px">-->
        </div>
      </div>

    </div>

    <div style="padding-bottom:10px;padding-top: 16px;"  v-if="isRectify">
      <van-row  type="flex" justify="center"  gutter="20">
        <van-col span="24">
          <van-button  size="large"  type="info"  :loading="changeLoading" @click="changeRectify">完成</van-button>
          <!--            <van-button plain hairline round size="large" type="info" :loading="loading" native-type="submit">提交</van-button>-->
        </van-col>
      </van-row>

    </div>

    <div style="padding-bottom:10px;padding-top: 16px;" v-if="buttonShow">
        <van-row  type="flex" justify="center"  gutter="20">

          <van-col span="24">
            <van-button  size="large"  type="info"  :loading="submitLoading" @click="changeStatus">确认</van-button>
<!--            <van-button plain hairline round size="large" type="info" :loading="loading" native-type="submit">提交</van-button>-->
          </van-col>
        </van-row>
      </div>
    <van-popup v-model="signShow" position="top" :style="{ height: '100%' }" :closeable="true" get-container="#app" >
      <signature @getSign="getSign" :signer="signer" :id="canvasId"  ref="signerContent"></signature>
    </van-popup>
  </div>
</template>
<script>
import Compressor from 'compressorjs';
import signature from "../map/components/signature";
import {
  Button, Col, Row,Uploader ,Image,Icon,RadioGroup, Radio,Form,Field,Notify,Dialog,NoticeBar,Popup,Toast
} from 'vant';
export default {
  data(){
    return {
      labelWidth:window.innerWidth - 165,
      formDict1:{},
      formDict2:{},
      formDict3:{},
      formShow1:false,
      formShow2:false,
      formShow3:false,
      buttonShow:true,
      formData:{},
      check:{},
      fileList:{},
      rectify:{},
      person:"",
      loading:false,
      submitLoading:false,
      changeLoading:false,
      noticeStr:"",
      isRectify:false,
      rectifySigned:false,
      signShow:false,
      signer:"",
      canvasId:"confirmSign",
      nowTime:"",
      sign:"",
      groupForm:{}
    }
  },
  components:{signature,
    [Button.name]: Button,
    [Uploader.name]: Uploader  ,
    [Icon.name]: Icon ,
    [Form.name]: Form,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Image.name]: Image ,
    [Notify.name]: Notify,
    [Popup.name]: Popup,
    [NoticeBar.name]: NoticeBar,
    [Dialog.name]: Dialog,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
    [Col.name]: Col,
    [Row.name]: Row
  },
  props:{
    recordData:{
      type:Object,
      default: ()=>{
        return {
        }
      }
    },
    dict:{
      type:Object,
      default: ()=>{
        return {}
      }
    },
  },
  watch: {
    /*监听主题变化*/
    recordData: {
      handler(n, o) {
        console.log(n,"n");
        if(n){
          let str = "";
          this.buttonShow = false;
          this.isRectify = false;
          if(n.status=="2"){
            this.buttonShow = true;
            this.sign = n.confirmSign;
            str = ",已整改，待审核。"
          }else if(n.status=="1"){
            this.isRectify = true;
            this.sign = n.rectifySign;
            str = ",待整改。"
          }else if(n.status=="3"){
            str = ",已整改。"
          }
          this.noticeStr = str;
          this.formData = JSON.parse(n.content);
          let proFiles = {};
          for(let k in this.formData){
            if(this.formData[k].fileList){
              if(!proFiles[k]){
                proFiles[k] = [];
              }
              proFiles[k] = this.formData[k].fileList.split(",").map(url=>{
                return {
                  url:url,
                  isImage: true
                }
              });
            }
          }
          console.log(proFiles,"proFiles")
          this.formDict1 = {};
          this.formDict2 = {};
          this.formDict3 = {};
          this.formShow1 = false;
          this.formShow2 = false;
          this.formShow3 = false;
          let showItem = [];

          if(n.problemList){
            n.problemList.map(problem=>{
              if(this.dict[problem.typeId]){
                showItem.push(this.dict[problem.typeId])
                this.$set(this.formDict3,problem.typeId,this.dict[problem.typeId])
              }
              let pics = [];
              let rectifyPics=[];
              if(problem.rectifyFileList){
                problem.rectifyFileList.map(pic=>{
                  rectifyPics.push({
                    url:pic,
                    isImage: true
                  })
                })
              }

              this.$set(this.rectify,problem.typeId,{
                des:problem.rectifyResult,
                id:problem.id,
                pics:rectifyPics,
                save:rectifyPics.length>0?true:false,
                checkResult:problem.checkResult,
                createTime:problem.createTime,
                typeId:problem.typeId
              })
              if(problem.recordFileList){
                problem.recordFileList.map(pic=>{
                  pics.push({
                    url:pic,
                    isImage: true
                  })
                })
              }

              if(proFiles[problem.typeId]){
                this.$set(this.fileList,problem.typeId,pics.concat(proFiles[problem.typeId]));
              }else{
                this.$set(this.fileList,problem.typeId,pics);
              }
              // this.$set(this.fileList,problem.typeId,pics);
            })
          }
          let group = {};
          showItem.map(item=>{
            if(!item.groupName){
              item.groupName = "其他";
            }
            if(!group[item.groupName]){
              group[item.groupName] = [];
            }
            group[item.groupName].push(item);
          })
          this.groupForm = group;
        }
      },
      deep: true,
      immediate:true
    }
  },
  mounted(){
    this.nowTime = this.getNowDate();
    console.log("this.dict",this.dict);
  },
  methods:{
    getNowDate(){
      let date = new Date();
      let myyear = date.getFullYear();
      let mymonth = date.getMonth()+1;
      let myweekday = date.getDate();
      let myHour = date.getHours();
      let myMin = date.getMinutes();
      let mySec= date.getSeconds();

      if(mymonth < 10){
        mymonth = "0" + mymonth;
      }
      if(myweekday < 10){
        myweekday = "0" + myweekday;
      }
      if(myHour < 10){
        myHour = "0" + myHour;
      }
      if(myMin < 10){
        myMin = "0" + myMin;
      }
      if(mySec < 10){
        mySec = "0" + mySec;
      }
      return (myyear+"-"+mymonth + "-" + myweekday + " " + myHour + ":" + myMin + ":" + mySec);
    },
    validatorPic(val,rule){
        return (Array.isArray(val)&&val.length>0)?true:false;
    },
    openSign(value){
      this.signer = value;
      this.signShow = true;
      this.$nextTick(()=>{
        this.$refs.signerContent.clearArea();
      })
    },
    getSign(value){
      console.log(value,"value.value")
      this.sign = value.content;
      this.rectifySigned = true;
      this.signShow = false;
    },
    onSubmit(id){
      let fileList = [];
      if(this.rectify[id]){
        this.rectify[id].pics.map((pic,index)=>{
          fileList.push({
            name:pic.file.name.replace(/image/,new Date().getTime() + id + "-"+  index ),
            content:pic.content
          })
        })
      }

      let problem = {
        id:this.rectify[id].id,
        checkResult:this.rectify[id].checkResult,
        typeId:this.rectify[id].typeId,
        createTime:this.rectify[id].createTime,
        rectifyResult:this.rectify[id].des,
        fileList:fileList,
        recordId:this.recordData.id
      };
      this.loading = true;
      this.$http({
        url: "/zuul/cultural/check/problem/",
        method: "post",
        data:problem
      }).then(res=>{
        if(res.data.success){
          this.rectify[id].save = true;
          Notify({ type: 'success', message: '提交成功' });
        }
      }).catch(error=>{
        Notify({ type: 'warning', message: '网络问题，请稍后再试' });
      }).finally(res=>{
        this.loading = false;
      })
    },
    changeRectify(){
      let validatorAll = true;
      for(let k in this.rectify){
        if(!this.rectify[k].save){
          validatorAll = false;
          break;
        }
      }
      console.log(validatorAll,"skks")
      if(!validatorAll){
        Notify({ type: 'warning', message: '未完全整改，不能结束！' });
        return false;
      }
      let problems = [];
      this.recordData.problemList.map(item=>{
        let p = {
          checkResult: item.checkResult,
          createTime:  item.createTime,
          id:  item.id,
          recordId:  item.recordId,
          rectifyResult: this.rectify[item.typeId].des,
          typeId: item.typeId
        }
        problems.push(p)
      })
      this.changeLoading = true;
      this.$http({
        url: "/zuul/cultural/check/",
        method: "post",
        data:{
          content:this.recordData.content,
          createTime: this.recordData.createTime,
          id: this.recordData.id,
          infoId: this.recordData.infoId,
          person: this.recordData.person,
          problemList:problems,
          rectify: this.recordData.rectify,
          remark: this.recordData.remark,
          status: 2,
          type: this.recordData.type,
          rectifySign:this.sign,
          rectifyBy:localStorage.getItem("wljptuser"),
          rectifyTime:this.nowTime
        }
      }).then(res=>{
        if(res.data.success){
          Notify({ type: 'success', message: '已提交' });
          this.isRectify = false;
        }
      }).finally(e=>{
        this.changeLoading = false;
      })
    },
    changeStatus(){
      this.submitLoading = true;
      let _self = this;
      Dialog.confirm({
            title: '提示',
            message: '确认整改完成？',
          })
          .then(() => {
            _self.$http({
              url: "/zuul/cultural/check/status?ids=" + _self.recordData.id + "&status=3&confirmBy=" + localStorage.getItem("wljptuser"),
              method: "post",
              headers: {
                'Content-Type': 'application/json'
              },
              data:this.sign
            }).then(res=>{
              if(res.data.success){
                Notify({ type: 'success', message: '整改完成' });
                _self.buttonShow = false;
              }
            }).finally(e=>{
              _self.submitLoading = false;
            })
          })
          .catch(() => {
            // on cancel
            _self.submitLoading = false;
            return false;
          });

    },
    beforeRead(file,defaultQuqlity=0.6) {
      let fileSize = file.size/1024/1024;
      let quality = defaultQuqlity;
      if(fileSize<0.5){
        quality = 1;
      }else if(fileSize<1){
        quality = 0.8
      }else if(fileSize<2){
        quality = 0.6
      }else if(fileSize<4){
        quality = 0.3   // 0.4 0.24
      }else if(fileSize<6){
        quality = 0.15  // 0.1 0.06   0.2-0.17
      }else if(fileSize<8.1){
        quality = 0.1
      }else{
        return new Promise((resolve,reject)=>{
          Toast('文件大小不能超过 8M');
          reject({
            msg:"图片不能超过6M"
          })

        })
      }
      return new Promise((resolve) => {
        // compressorjs 默认开启 checkOrientation 选项
        // 会将图片修正为正确方向
        new Compressor(file, {
          quality: quality,
          success(result){
            console.log(result.size/1024/1024,"111")
            resolve(result);
          },
          error(err) {
            console.log(err.message);
          },
        });
      });
    },
    onOversize(file) {
      Toast('文件大小不能超过 500kb');
    },
  }
}
</script>
<style scoped lang="less">

.detail{
  padding-bottom: 0px;
  padding-top:60px;
  background: #f4f6fa;
}
.form-title{
  //color: rgba(69, 90, 100, 0.6);
  font-size: 1.1rem;
  color:#4E555B;
  line-height: 40px;
  padding: 5px 16px 0px;
  /*background: #f7f8fa;*/
  //background: #bae7ff;
  //background: rgba(162, 183, 73, 0.2);
}
.type-title{

  margin: 0px 16px 20px;
  //border: 1px solid rgba(237, 106, 12,0.5);
  background: white;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(225, 230, 236, 100);
}
.form-label{
  padding: 10px 12px 0px;
  color: #646566;
}
.item-list{
  padding: 0px 16px;
}
.item-title{
  //color: #646566;
  color:#1E6CF7;
  padding: 10px 0px;
  font-size:1.1rem;
  border-bottom: 1px solid #eee;

  .item-index{
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    background: #40a9ff;
    text-align: center;
    color: white;
    border-radius: 50%;
  }
}
.item-check-des{
  padding:5px 10px;
  color: #F7671E;
  font-size:1rem;

}
.rectify-content{
  //border: 1px solid rgba(0,0,0,0.1);

  .item-btn{
    padding: 20px 20px;
  }
}
.rectify-title{
  line-height: 40px;
  padding: 0px 10px;

}
/deep/ .van-radio-group--horizontal{
  width: 150px;
}
/deep/ .van-field__body textarea{
  background: rgba(244, 246, 250, 100);
  padding: 10px;
}
</style>
